<template>
  <div>
    <InverterFarmCompariton :inverterdata="inverterdata" />
  </div>
</template>

<script>
import InverterFarmCompariton from "@/views/components/Inverter/InverterFarmCompariton.vue";

export default {
  components: {
    InverterFarmCompariton,
  },
  data() {
    return {
      inverterdata: {},
    };
  },
  computed: {},

  mounted() {
    const myVar1 = setInterval(myTimer1, 1000);
    const windowsWidth = document.documentElement.clientWidth;

    const self = this;
    let k = 0;
    function myTimer1() {
      if (k > 30) {
        clearInterval(myVar1);
      }

      k += 1;
      const { santralInverterData, santralDataList, inverterMeasureList } =
        self.$store.getters;
      if (
        Object.keys(inverterMeasureList).length > 0 &&
        Object.keys(santralDataList).length > 0 &&
        Object.keys(santralInverterData).length > 0
      ) {
        clearInterval(myVar1);
        self.inverterdata = {
          measures: inverterMeasureList,
          santralList: santralDataList,
          santralInverterData,
          windowsWidth,
        };
        console.log(self.inverterdata);
      }
    }
  },
  methods: {},
};
</script>
